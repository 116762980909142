import React, { useState } from 'react';
import { AiFillHeart } from 'react-icons/ai';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { isEmpty } from '../utils/validation';
import {useTranslation} from "react-i18next";

const DeleteAccountPage = () => {
    const t = useTranslation().t;
    const { deleteUser } = useAuth();
    const navigate = useNavigate();
    const [state, setState] = useState({
        email: '',
    });
    const [errors, setErrors] = useState({
        email: false,
        noexist: false,
    });
    const [invalid, setInvalid] = useState(false);

    const validate = () => {
        let hasErrors = false;
        let errors = {};
        for (const key in state) {
            if (isEmpty(state[key])) {
                errors[key] = `${key} ${t('lbl_is_required')}`;
                hasErrors = true;
            }
        }

        if (hasErrors) {
            setErrors(errors);
        }
        return !hasErrors;
    };

    const submit = async (e) => {
        e.preventDefault();
        if (validate()) {
            try {
                await deleteUser(state);
                navigate('/login', { replace: true, state: { deleted: true } });
            } catch (err) {
                setInvalid(true);
            }
        }
    };

    const change = (e) => {
        if (isEmpty(e.target.value)) {
            setErrors({ ...errors, [e.target.name]: `${e.target.name} is required` });
        } else {
            setErrors({ ...errors, [e.target.name]: false });
        }
        setState({ ...state, [e.target.name]: e.target.value });
    };

    return (
        <div className='auth-page-wrapper pt-5'>
            <div className='auth-one-bg-position auth-one-bg' id='auth-particles'>
                <div className='bg-overlay'></div>

                <div className='shape'>
                    <svg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 1440 120'>
                        <path d='M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z'></path>
                    </svg>
                </div>
            </div>
            <div className='auth-page-content'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-md-8 col-lg-6 col-xl-5'>
                            <div className='card mt-4'>
                                <div className='card-body p-5'>
                                    <div className='text-center mt-2'>
                                        <h5 className='text-primary'>{t('txt_delete_account')}</h5>
                                        <p className='text-muted'>{t('txt_no_problem_delete_account')}</p>
                                    </div>
                                    <div className='p-2 mt-4'>
                                        <form onSubmit={submit}>
                                            <div className='mb-3'>
                                                <label htmlFor='email' className='form-label'>
                                                    {t('lbl_email')}
                                                </label>
                                                <input
                                                    type='text'
                                                    className={`form-control ${errors.email && 'is-invalid'}`}
                                                    name='email'
                                                    placeholder={t('lbl_email_input')}
                                                    onChange={change}
                                                    value={state.email}
                                                />
                                                <div className='invalid-feedback'>{errors.email}</div>
                                            </div>

                                            <div className='mt-4'>
                                                <button className='btn btn-success w-100' type='submit'>
                                                    {t('btn_delete_account')}
                                                </button>
                                            </div>
                                            {invalid &&
                                            <span className='text-danger'>{t('txt_error_email_not_exist')} <Link to='/signup'>{t('btn_here')}</Link>.</span>
                                            }
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div className='mt-4 text-center'>
                                <p className='mb-0'>
                                    {t('txt_back_to_login')}
                                    <Link
                                        to='/login'
                                        className='ms-1 fw-semibold text-primary text-decoration-underline'
                                    >
                                        Login
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <footer className='footer'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='text-center'>
                                <p className='mb-0 text-muted'>
                                    ©2024 DCV. Crafted with <AiFillHeart className='text-danger' /> by orcas
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default DeleteAccountPage;
