import React, {Fragment, useState, useEffect, useRef} from "react";
import moment from 'moment';
import { RiSaveLine, RiUploadLine } from 'react-icons/ri';
import {useAuth} from "../../hooks/useAuth";
import {isEmpty} from "../../utils/validation";
import StatusBadge from "../../parts/StatusBadge";
import {useAutoSave} from "../../hooks/useAutoSave";
import UserService from "../../services/UserService";
import ImageUploadService from "../../services/ImageUploadService";
import {useTranslation} from "react-i18next";
import config from '../../config/config.json';


const Profile = () => {
    /**
     * States
     */
    const t = useTranslation().t;
    const { register } = useAutoSave();
    const { user } = useAuth();
    const [state, setState] = useState({
        user: null,
        applications: null,
        firstname: '',
        lastname: '',
        street: '',
        zip: '',
        city: '',
        profileImageUrl: null,
        role: user.role,
    });
    const [initialState, setInitialState] = useState({
        user: null,
        applications: null,
        firstname: '',
        lastname: '',
        street: '',
        zip: '',
        city: '',
        profileImageUrl: null,
        role: user.role,
    });
    const [selectedImage, setSelectedImage] = useState(null);
    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState(false);
    const [successUpload, setSuccessUpload] = useState(false);

    /**
     * Refs
     */
    const stateRef = useRef({});
    stateRef.current = state;
    const initialStateRef = useRef({});
    initialStateRef.current = initialState;

    /**
     * Services
     */
    useEffect(() => {
        fetch()
    }, [user.id]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
            registerAutoSave();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps


    /**
     * Functions
     */
    const registerAutoSave = () => {
        register(doSubmit);
    }

    const fetch = async () =>{
        try {
            const response = await UserService.getUser(user.id);
            const data = {
                user: response.data.user,
                applications: response.data.existingApplications,
                firstname: response.data.user.firstName,
                lastname: response.data.user.lastName,
                street: response.data.user.street,
                zip: response.data.user.zip,
                city: response.data.user.city,
                profileImageUrl: response.data.profileImageUrl,
                role: response.data.user.role,
            }
            setState({...data});
            setInitialState({...data});
        } catch (err) {
            setErrors(err.response.data.errors);
            console.error(err)
        }
    }

    const doSubmit = async () => {
        if(JSON.stringify(stateRef.current) !== JSON.stringify(initialStateRef.current)) {
            try {
                await UserService.putUser(stateRef.current, user.id);
                setSuccess(true);
            } catch (err) {
                setErrors(err.response.data.errors);
            }
        }
    }

    const submit = async (e) => {
        e.preventDefault();
        try {
            await UserService.putUser(stateRef.current, user.id);
            setSuccess(true);
            setInitialState({...state})
        } catch (err) {
            setErrors(err.response.data.errors);
        }
    };

    const change = (e) => {
        if (isEmpty(e.target.value)) {
            setErrors({ ...errors, [e.target.name]: `${e.target.name} is required` });
        } else {
            setErrors({ ...errors, [e.target.name]: false });
        }
        setState({ ...state, [e.target.name]: e.target.value });
    };

    const handleImageChange = (e) => {
        const imageFile = e.target.files[0];
        if (imageFile) {
            setSelectedImage(imageFile);
            setState({
                ...state,
                profileImageUrl: URL.createObjectURL(imageFile),
            });
        }
    };

    const handleImageUpload = async (e) => {
        e.preventDefault();
        if (!selectedImage) return;
        const formData = new FormData();
        formData.append('image', selectedImage);

        try {
            await ImageUploadService.imageUpload(formData);
            setSuccessUpload(true);
        } catch (error) {
            console.error('Error uploading image:', error);
        }
    };

    return (
        <Fragment>
            <div className='row'>
                <div className='col-12'>
                    <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
                        <h4 className='mb-sm-0'>{t('lbl_profile_details')}</h4>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-xxl-3'>
                    <div className='card'>
                        <div className='card-body'>
                            <div className="text-center">
                                <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                                    {state.profileImageUrl ? (
                                        <img
                                            src={state.profileImageUrl.startsWith('blob:')
                                                ? state.profileImageUrl
                                                : `${config.serverUrl}${state.profileImageUrl}`}
                                            className='rounded-circle avatar-xl img-thumbnail user-profile-image'
                                            alt="Selected"
                                        />
                                    ) : (
                                        <span style={{display: "flex", justifyContent: "center", alignItems: "center"}}
                                              className='rounded-circle avatar-xl img-thumbnail user-profile-image'>{t('lbl_no_image')}</span>
                                    )}
                                    <form encType="multipart/form-data" name="avatar" onSubmit={handleImageUpload}>
                                        <div className="avatar-xs p-0 rounded-circle profile-photo-edit profile-photo-edit2">
                                            <input className="profile-img-file-input" id="profile-img-file-input" type="file" accept="image/*" onChange={handleImageChange} />
                                            <label htmlFor="profile-img-file-input"
                                                   className="profile-photo-edit avatar-xs">
                                                <span className="avatar-title rounded-circle bg-light text-body">
                                                    <RiUploadLine />
                                                </span>
                                            </label>
                                        </div>
                                        <div className="avatar-xs p-0 rounded-circle profile-photo-edit profile-photo-save">
                                            <input type="submit" className="profile-img-file-input" id="profile-img-save-input" />
                                            <label htmlFor="profile-img-save-input"
                                                   className="profile-photo-edit avatar-xs">
                                                <span className="avatar-title rounded-circle bg-success">
                                                    <RiSaveLine />
                                                </span>
                                            </label>
                                        </div>
                                    </form>
                                </div>
                                {successUpload &&
                                    <div className="mt-4 text-center">
                                        <span className='text-success'>{t('lbl_saved')}</span>
                                    </div>
                                }

                                <div className="mt-4 text-center">
                                    <h5 className="mb-1">{user.email}</h5>
                                    <p className="text-muted">{t(`lbl_${state.user && state.user.role.toLowerCase()}`)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card'>
                        <form onSubmit={submit}>
                            <div className='card-body'>
                                <h6 className="card-title flex-grow-1 text-start">{t('lbl_current_user_information')}</h6>
                                <div className="table-card">
                                    <div className="row g-3 mb-4 p-4">
                                        <div className="col-12">
                                            <div className="fw-medium">{t('lbl_firstname')}</div>
                                            <input
                                                type="text"
                                                id="firstname"
                                                name='firstname'
                                                onChange={change}
                                                value={state.firstname}
                                                className="form-control"
                                                placeholder={t('lbl_firstname_input')}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <div className="fw-medium">{t('lbl_lastname')}</div>
                                            <input
                                                type="text"
                                                id="lastname"
                                                name='lastname'
                                                onChange={change}
                                                value={state.lastname}
                                                className="form-control"
                                                placeholder={t('lbl_lastname_input')}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <div className="fw-medium">{t('lbl_street')}</div>
                                            <input
                                                type="text"
                                                id="street"
                                                name='street'
                                                onChange={change}
                                                value={state.street}
                                                className="form-control"
                                                placeholder={t('lbl_street_input')}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <div className="fw-medium">{t('lbl_zip')}</div>
                                            <input
                                                type="text"
                                                id="zip"
                                                name='zip'
                                                onChange={change}
                                                value={state.zip}
                                                className="form-control"
                                                placeholder={t('lbl_zip_input')}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <div className="fw-medium">{t('lbl_city')}</div>
                                            <input
                                                type="text"
                                                id="city"
                                                name='city'
                                                onChange={change}
                                                value={state.city}
                                                className="form-control"
                                                placeholder={t('lbl_city_input')}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="text-end">
                                        <button type="submit" className="btn btn-primary">{t('btn_save')}</button>
                                    </div>
                                    {success && <span className='text-success'>{t('lbl_saved')}</span>}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className='col-xxl-9'>
                    {state.applications && state.applications.map((application, index) => (
                        <div key={index}>
                            {application.status === "open" && (
                                <div className='card'>
                                    <div className='card-body'>
                                        {t('txt_current_request_status')} <StatusBadge status={application.status} />. {t('txt_email_if_changes')}
                                    </div>
                                </div>
                            )}
                            <div className='card'>
                                <div className="card-header border-0 align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">{t('lbl_request_for')} {application.company ? application.company.companyName : `${application.person.firstName} ${application.person.lastName}`}</h4>
                                </div>
                                <div className='card-body'>
                                    <div className="table mb-4">
                                        {application.company && (
                                            <div>
                                                <div className={'row mb-2 border-bottom'}>
                                                    <div className="fw-medium col-md-6">{t('lbl_current_status')}</div>
                                                    <div className={'col-md-6'}>
                                                        <StatusBadge status={application.status}/>
                                                    </div>
                                                </div>
                                                <div className={'row border-bottom'}>
                                                    <div className="fw-medium col-md-6">{t('lbl_created_date')}</div>
                                                    <div className={'col-md-6'}>
                                                        {moment(application.createdDate).format('DD.MM.YYYY')}
                                                    </div>
                                                </div>
                                                <div className={'row mb-2 border-bottom'}>
                                                    <div className="fw-medium col-md-6">
                                                        {t('lbl_name')}
                                                    </div>
                                                    <div className={'col-md-6'}>
                                                        {application.company.companyName}
                                                    </div>
                                                </div>
                                                <div className={'row mb-2 border-bottom'}>
                                                    <div className="fw-medium col-md-6">
                                                        {t('lbl_date_of_foundation')}
                                                    </div>
                                                    <div className={'col-md-6'}>
                                                        {moment(application.company.companyDate).format('DD.MM.YYYY')}
                                                    </div>
                                                </div>
                                                <div className={'row mb-2 border-bottom'}>
                                                    <div className="fw-medium col-md-6">
                                                        {t('lbl_registration_nr')}
                                                    </div>
                                                    <div className={'col-md-6'}>
                                                        {application.company.companyReg}
                                                    </div>
                                                </div>
                                                <div className={'row mb-2 border-bottom'}>
                                                    <div className="fw-medium col-md-6">
                                                        {t('lbl_contact_person')}
                                                    </div>
                                                    <div className={'col-md-6'}>
                                                        {application.person.lastName}, {application.person.firstName} ({application.company.position})
                                                    </div>
                                                </div>
                                                <div className={'row mb-2 border-bottom'}>
                                                    <div className="fw-medium col-md-6">
                                                        {t('lbl_email')}
                                                    </div>
                                                    <div className={'col-md-6'}>
                                                        {application.person.email}
                                                    </div>
                                                </div>
                                                <div className={'row mb-2 border-bottom'}>
                                                    <div className="fw-medium col-md-6">
                                                        {t('lbl_phone')}
                                                    </div>
                                                    <div className={'col-md-6'}>
                                                        {application.person.phone}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {!application.company && (
                                            <div>
                                                <div className={'row mb-2 border-bottom'}>
                                                    <div className="fw-medium col-md-6">
                                                        {t('lbl_current_status')}
                                                    </div>
                                                    <div className={'col-md-6'}>
                                                        <StatusBadge status={application.status}/>
                                                    </div>
                                                </div>
                                                <div className={'row mb-2 border-bottom'}>
                                                    <div className="fw-medium col-md-6">
                                                        {t('lbl_created_date')}
                                                    </div>
                                                    <div className={'col-md-6'}>
                                                        {moment(application.createdDate).format('DD.MM.YYYY')}
                                                    </div>
                                                </div>
                                                <div className={'row mb-2 border-bottom'}>
                                                    <div className="fw-medium col-md-6">
                                                        {t('lbl_email')}
                                                    </div>
                                                    <div className={'col-md-6'}>
                                                        {application.person.email}
                                                    </div>
                                                </div>
                                                <div className={'row mb-2 border-bottom'}>
                                                    <div className="fw-medium col-md-6">
                                                        {t('lbl_phone')}
                                                    </div>
                                                    <div className={'col-md-6'}>
                                                        {application.person.phone}
                                                    </div>
                                                </div>
                                                <div className={'row mb-2 border-bottom'}>
                                                    <div className="fw-medium col-md-6">
                                                        {t('lbl_birth_date')}
                                                    </div>
                                                    <div className={'col-md-6'}>
                                                        {moment(application.person.dateOfBirth).format('DD.MM.YYYY')}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        )
                    )}
                </div>
            </div>
        </Fragment>
    );
};

export default Profile;
