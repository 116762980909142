import React from 'react';
import Footer from '../parts/Footer';
import MainContent from '../parts/MainContent';
import Sidebar from '../parts/Sidebar';
import TopBar from '../parts/TopBar';
import { Routes, Route, Navigate } from 'react-router-dom';
import Sample from '../modules/Sample/Sample';
import MemberForm from '../modules/MemberForm/MemberForm';
import ApplicationList from "../modules/ApplicationList/ApplicationList";
import Application from "../modules/Application/Application";
import Profile from "../modules/Profile/Profile";
import MemberList from "../modules/MemberList/MemberList";
import DashboardTemplate from "../modules/Dashboard/Dashboard";
import MemberCard from "../modules/MemberCard/MemberCard";
import UserList from "../modules/UserList/UserList";

const Dashboard = () => {
    const watchers = {
        collection: [],
        register: function(key, callBack){
            this.collection.push({name: key, callBack: callBack})
        },
        notify: function (key) {
            for(let ii = 0; ii < this.collection.length; ii++){
                if(key === this.collection[ii].name){
                    this.collection[ii].callBack();
                }
            }
        }
    }
    return (
        <div id='layout-wrapper'>
            <TopBar />
            <Sidebar watchers={watchers}/>
            <MainContent>
                <Routes>
                    <Route path={'/'} element={<Navigate to='/dashboard' />} />
                    <Route path='/sample' element={<Sample />} />
                    <Route path='/profile' element={<Profile />} />
                    <Route path='/member-form' element={<MemberForm />} />
                    <Route path='/application-list' element={<ApplicationList />}/>
                    <Route path='/member-list' element={<MemberList />}/>
                    <Route path='/user-list' element={<UserList />}/>
                    <Route path='/member-card' element={<MemberCard />}>
                        <Route path=':id' element={<MemberCard/>} />
                    </Route>
                    <Route path='/dashboard' element={<DashboardTemplate />}/>
                    <Route path='/application' element={<Application watchers={watchers}/>}>
                        <Route path=':id' element={<Application watchers={watchers}/>} />
                    </Route>
                </Routes>
                <Footer />
            </MainContent>
        </div>
    );
};

export default Dashboard;
