import React, { useState } from 'react';
import { AiFillHeart } from 'react-icons/ai';
import {useNavigate, Link, useLocation, useSearchParams} from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { isEmpty } from '../utils/validation';
import Version from "../parts/Version";
import {useTranslation} from "react-i18next";

const LoginPage = () => {
    const t = useTranslation().t;
    const { login } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [state, setState] = useState({
        email: '',
        password: '',
    });
    const [errors, setErrors] = useState({
        email: false,
        password: false,
    });

    const [searchParams] = useSearchParams();

    const [invalid, setInvalid] = useState(false);
    const passwordChanged = location.state?.passwordChanged || false;
    const passwordReseted = location.state?.passwordReseted || false;
    const deleted = location.state?.deleted || false;

    const validate = () => {
        let hasErrors = false;
        let errors = {};
        for (const key in state) {
            if (isEmpty(state[key])) {
                errors[key] = `${key} ${t('lbl_is_required')}`;
                hasErrors = true;
            }
        }

        if (hasErrors) {
            setErrors(errors);
        }
        return !hasErrors;
    };

    const submit = async (e) => {
        e.preventDefault();
        if (validate()) {
            try {
                await login(state);
                const redirectRoute = searchParams.get('redirect');

                if(redirectRoute){
                    navigate(decodeURIComponent(redirectRoute), { replace: true });
                } else{
                    navigate('/dashboard', { replace: true });
                }
            } catch (err) {
                setInvalid(true);
            }
        }
    };

    const change = (e) => {
        if (isEmpty(e.target.value)) {
            setErrors({ ...errors, [e.target.name]: `${e.target.name} is required` });
        } else {
            setErrors({ ...errors, [e.target.name]: false });
        }
        setState({ ...state, [e.target.name]: e.target.value });
    };

    return (
        <div className='auth-page-wrapper pt-5'>
            <div className='auth-one-bg-position auth-one-bg' id='auth-particles'>
                <div className='bg-overlay'></div>

                <div className='shape'>
                    <svg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 1440 120'>
                        <path d='M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z'></path>
                    </svg>
                </div>
            </div>
            <div className='auth-page-content'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-md-8 col-lg-6 col-xl-5'>
                            <div className='card mt-4'>
                                <div className='card-body p-4'>
                                    <div className='text-center mt-2'>
                                        <h5 className='text-primary'>{t('txt_welcomeback')}</h5>
                                        <p className='text-muted'>{t('txt_signin')}</p>
                                    </div>
                                    {invalid && <span className='text-danger'>{t('lbl_invalid')}</span>}
                                    {passwordChanged && (
                                        <div className='alert alert-success mt-3'>
                                            {t('lbl_password_change_success')}
                                        </div>
                                    )}
                                    {passwordReseted && (
                                        <div className='alert alert-success mt-3'>
                                            {t('lbl_password_change_check')}
                                        </div>
                                    )}
                                    {deleted && (
                                        <div className='alert alert-success mt-3'>
                                            {t('lbl_deleted')}
                                        </div>
                                    )}
                                    <div className='p-2 mt-4'>
                                        <form onSubmit={submit}>
                                            <div className='mb-3'>
                                                <label htmlFor='email' className='form-label'>
                                                    {t('lbl_email')}
                                                </label>
                                                <input
                                                    type='email'
                                                    className={`form-control ${errors.email && 'is-invalid'}`}
                                                    name='email'
                                                    placeholder={t('lbl_email_input')}
                                                    onChange={change}
                                                    value={state.email}
                                                />
                                                <div className='invalid-feedback'>{errors.email}</div>
                                            </div>

                                            <div className='mb-3'>
                                                <div className='float-end'>
                                                    <Link to='/forgot-password' className='text-muted'>
                                                        {t('lbl_password_forgot')}
                                                    </Link>
                                                </div>
                                                <label className='form-label' htmlFor='password'>
                                                    {t('lbl_password')}
                                                </label>
                                                <div className='position-relative auth-pass-inputgroup mb-3'>
                                                    <input
                                                        type='password'
                                                        className={`form-control pe-5 password-input ${
                                                            errors.password && 'is-invalid'
                                                        }`}
                                                        placeholder={t('lbl_password_input')}
                                                        name='password'
                                                        onChange={change}
                                                        value={state.password}
                                                    />
                                                    <div className='invalid-feedback'>{errors.password}</div>
                                                </div>
                                            </div>

                                            <div className='form-check'>
                                                <input
                                                    className='form-check-input'
                                                    type='checkbox'
                                                    value=''
                                                    id='auth-remember-check'
                                                />
                                                <label className='form-check-label' htmlFor='auth-remember-check'>
                                                    {t('lbl_remember')}
                                                </label>
                                            </div>

                                            <div className='mt-4'>
                                                <button className='btn btn-success w-100' type='submit'>
                                                    {t('btn_signin')}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div className='mt-4 text-center'>
                                <p className='mb-0'>
                                    {t('txt_noaccount')}
                                    <Link
                                        to='/signup'
                                        className='ms-1 fw-semibold text-primary text-decoration-underline'
                                    >
                                        {t('btn_signup')}
                                    </Link>
                                </p>
                                <p className='mb-0'>
                                    {t('txt_to_deleteaccount')}
                                    <Link
                                        to='/delete-account'
                                        className='ms-1 fw-semibold text-primary text-decoration-underline'
                                    >
                                        {t('btn_to_deleteaccount')}
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <footer className='footer'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='text-center'>
                                <p className='mb-0 text-muted'>
                                    ©2024 DCV. Crafted with <AiFillHeart className='text-danger' /> by orcas
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <Version />
        </div>
    );
};

export default LoginPage;
