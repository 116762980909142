import axios from 'axios';
import Service from './Service';
import config from '../config/config.json';

class UserService extends Service {
    static async putUser(state, id) {
        try {
            await axios.put(`${config.serverUrl}/api/v1/user/${id}`, state, {
                headers: {
                    'X-Auth-Token': this.token,
                },
            });
        } catch (err) {
            throw err;
        }
    }

    static async getUser(id) {
        try {
            return axios.get(`${config.serverUrl}/api/v1/user/${id}`, {
                headers: {
                    'X-Auth-Token': this.token,
                },
            });
        } catch (err) {
            throw err;
        }
    }

    static async getUsers() {
        try {
            return axios.get(`${config.serverUrl}/api/v1/user`, {
                headers: {
                    'X-Auth-Token': this.token,
                },
            });
        } catch (err) {
            throw err;
        }
    }

    static async roleToAdmin(id) {
        try {
            return axios.put(
                `${config.serverUrl}/api/v1/user/${id}`,
                { role: 'Administrator' },
                {
                    headers: {
                        'X-Auth-Token': this.token,
                    },
                }
            );
        } catch (err) {
            throw err;
        }
    }

    static async roleToMember(id) {
        try {
            return axios.put(
                `${config.serverUrl}/api/v1/user/${id}`,
                { role: 'Member' },
                {
                    headers: {
                        'X-Auth-Token': this.token,
                    },
                }
            );
        } catch (err) {
            throw err;
        }
    }

    static async roleToCustomer(id) {
        try {
            return axios.put(
                `${config.serverUrl}/api/v1/user/${id}`,
                { role: 'Customer' },
                {
                    headers: {
                        'X-Auth-Token': this.token,
                    },
                }
            );
        } catch (err) {
            throw err;
        }
    }

    static async revertDeleteRequest(id, state) {
        try {
            state = { ...state, deleteRequest: false };
            return axios.put(
                `${config.serverUrl}/api/v1/user/${id}`, state,
                {
                    headers: {
                        'X-Auth-Token': this.token,
                    },
                }
            );
        } catch (err) {
            throw err;
        }
    }
}

export default UserService;
