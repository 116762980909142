import { ProvideAuth } from "./hooks/useAuth";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import SignUpPage from "./pages/SignUpPage";
import ThankYouPage from "./pages/ThankYouPage";
import PasswordResetPage from "./pages/PasswordResetPage";
import Protected from "./components/Protected";
import Dashboard from "./pages/Dashboard";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import {ProvideAutoSave} from "./hooks/useAutoSave";
import $ from "jquery";
import { useEffect } from "react";
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import de_lang from "./languages/DE_de";
import DeleteAccountPage from "./pages/DeleteAccountPage";

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        resources: {
            de: {
                translation: de_lang
            }
        },
        lng: "de", // if you're using a language detector, do not define the lng option
        fallbackLng: "en",

        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        }
    });

function App() {
    useEffect((e) => {
        document.body.addEventListener("click", function (e) {
            $(".dropdown-menu").removeClass("show");
            $(".btn-topbar").removeClass("show");
            $(".dd-link").removeClass("show");
        });
    }, []);

    return (
        <ProvideAuth>
            <Router>
                <Routes>
                    <Route path='/login' element={<LoginPage />} />
                    <Route path='/signup' element={<SignUpPage />} />
                    <Route path='/forgot-password' element={<ForgotPasswordPage />} />
                    <Route path='/password-reset' element={<PasswordResetPage />} />
                    <Route path='/delete-account' element={<DeleteAccountPage />} />
                    <Route path='/thankyou' element={<ThankYouPage />} />
                    <Route path='/*' element={
                            <Protected>
                                <ProvideAutoSave>
                                    <Dashboard />
                                </ProvideAutoSave>
                            </Protected>
                        }
                    />
                </Routes>
            </Router>
        </ProvideAuth>
    );
}

export default App;
