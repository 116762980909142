import React, {useRef, useEffect} from 'react';
import SidebarLogo from './SidebarLogo';
import { RiDashboard2Line, RiDraftLine, RiUser3Line, RiCommunityLine, RiBankCard2Line, RiFileEditLine } from 'react-icons/ri';
import SimpleBar from 'simplebar-react';
import SidebarTitle from './SidebarTitle';
import NavLink from './NavLink';
import AdminProtected from "../components/AdminProtected";
import MemberProtected from "../components/MemberProtected";
import {useAuth} from "../hooks/useAuth";
import {useTranslation} from "react-i18next";
import OnlyNotMember from "../components/OnlyNotMember";
import OrcasProtected from "../components/OrcasProtected";


const Sidebar = ({watchers, ...props}) => {
    const t = useTranslation().t;
    const { user } = useAuth();

    const sidebarRef = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                document.documentElement.dataset.sidebarSize = 'lg';
                document.documentElement.classList.remove('mobileOpen');
                document.getElementById('topnav-hamburger-icon-span').classList.remove('open');
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [sidebarRef]);

    return (
        <div className='app-menu navbar-menu' ref={sidebarRef}>
            <SidebarLogo />
            <ul className='navbar-nav'>
                <SimpleBar className='navbar-nav'>
                    <SidebarTitle>{t('lbl_menu')}</SidebarTitle>
                    <NavLink icon={<RiDashboard2Line />} to='/dashboard' title='Dashboard' />
                    <NavLink icon={<RiUser3Line />} to='/profile' title={t('lbl_profile')} />
                    <OnlyNotMember>
                        <NavLink icon={<RiFileEditLine />} to='/member-form' title={t('lbl_member_form')} />
                    </OnlyNotMember>
                    <MemberProtected>
                        <NavLink icon={<RiBankCard2Line />} to={`/member-card/${user.id}`} title={t('lbl_member_card')}/>
                    </MemberProtected>
                    <AdminProtected>
                        <NavLink icon={<RiDraftLine />} to='/application-list' title={t('txt_requests')} badge watchers={watchers} />
                    </AdminProtected>
                    <AdminProtected>
                        <NavLink icon={<RiCommunityLine />} to='/member-list' title={t('txt_members')} watchers={watchers} />
                    </AdminProtected>
                    <AdminProtected>
                        <NavLink icon={<RiUser3Line />} to='/user-list' title={'User List'} />
                    </AdminProtected>
                </SimpleBar>
            </ul>

            <div className='sidebar-background' />
        </div>
    );
};

export default Sidebar;
