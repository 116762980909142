import React, {Fragment, useState, useEffect} from "react";
import {useParams} from 'react-router-dom';
import {QRCodeSVG} from 'qrcode.react';
import moment from 'moment';
import {useAuth} from "../../hooks/useAuth";
import StatusBadge from "../../parts/StatusBadge";
import UserService from "../../services/UserService";
import {useTranslation} from "react-i18next";
import config from "../../config/config.json";

const MemberCard = ({watchers, ...props}) => {
    const t = useTranslation().t;
    const { user } = useAuth();
    const { id } = useParams();
    const [state, setState] = useState({
        application: null,
        newStatus: '',
        createdDate: '',
        currentStatus: '',
        approvedDate: '',
        validUntil: '',
        rejectedUntil: '',
        mailSend: 'yes',
        id: id,
        authenticated: false
    });

    useEffect(() => {
        fetch()
    }, [id]);// eslint-disable-line react-hooks/exhaustive-deps

    const fetch = async () =>{
        try {
            const response = await UserService.getUser(id);
            setState({
                ...state,
                user: response.data.user,
                applications: response.data.existingApplications,
                firstname: response.data.user.firstName,
                lastname: response.data.user.lastName,
                street: response.data.user.street,
                zip: response.data.user.zip,
                city: response.data.user.city,
                profileImageUrl: response.data.profileImageUrl,
            });
        } catch (err) {
            console.error(err)
        }
    }

    if(state.user && (state.user.role === "Member" || state.user.role === 'Administrator')){
        state.authenticated = true;
    }

    return (
        <Fragment>
            <div className='row'>
                <div className='col-12'>
                    <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
                        <h4 className='mb-sm-0'>{t('lbl_member_card')}</h4>
                    </div>
                </div>
            </div>
            {state.authenticated && (
                <div className='row'>
                    <div className='col-xxl-6'>
                        {state.applications && state.applications.map((application, index) => (
                                <div className='card' key={index}>
                                    <div className='card-body'>
                                        <div className="row">
                                            <div className='col-xxl-9'>
                                                <div className="table-responsive">
                                                    <table className="table table-borderless mb-0">
                                                        <tbody>
                                                        <tr className={'mobileStatus'}>
                                                            <th className="ps-0" scope="row">{t('lbl_status')}:</th>
                                                            <td className="text-muted">
                                                                <StatusBadge status={application.status} />
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <h5 className="card-title mb-3">{application.company ? application.company.companyName : `${application.person.firstName} ${application.person.lastName}`}</h5>
                                                {application.company && (
                                                    <div className="table-responsive">
                                                        <table className="table table-borderless mb-0">
                                                            <tbody>
                                                            <tr>
                                                                <th className="ps-0" scope="row">{t('lbl_registered_as')}:</th>
                                                                <td className="text-muted">{application.company ? t('lbl_company') : t('lbl_person')}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="ps-0" scope="row">{t('lbl_address')}:</th>
                                                                <td className="text-muted">
                                                                    {state.user.street && state.user.street !== "" ? state.user.street : application.company.companyStreet }, {state.user.zip && state.user.zip !== "" ? state.user.zip : application.company.companyZip} {state.user.city && state.user.city !== "" ? state.user.city : application.company.companyCity}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th className="ps-0"
                                                                    scope="row">{t('lbl_contact_person')}:
                                                                </th>
                                                                <td className="text-muted">{application.person.lastName}, {application.person.firstName} ({application.company.position})</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="ps-0" scope="row">{t('lbl_member_status')}:</th>
                                                                <td className="text-muted"> {application.rejectedUntil ? (
                                                                    <div>
                                                                        {t('lbl_member_rejected_until')} {application.rejectedUntil && moment(application.rejectedUntil).format('DD.MM.YYYY')}
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        {t('lbl_member_since')} {application.approvedDate && moment(application.approvedDate).format('DD.MM.YYYY')} {t('lbl_until')} {application.validUntil && moment(application.validUntil).format('DD.MM.YYYY')} {application.status === 'canceled' ? `(${t('lbl_canceled')})` : ''} {application.status === 'suspended' ? `(${t('lbl_suspended')})` : ''}
                                                                    </div>
                                                                )}
                                                                </td>
                                                            </tr>
                                                            <tr className={'desktopStatus'}>
                                                                <th className="ps-0" scope="row">{t('lbl_status')}:</th>
                                                                <td className="text-muted">
                                                                    <StatusBadge status={application.status} />
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )}
                                                {!application.company && (
                                                    <div className="table-responsive">
                                                        <table className="table table-borderless mb-0">
                                                            <tbody>
                                                            <tr>
                                                                <th className="ps-0" scope="row">{t('lbl_registered_as')}:</th>
                                                                <td className="text-muted">{application.company ? t('lbl_company') : t('lbl_person')}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="ps-0" scope="row">{t('lbl_address')}:</th>
                                                                <td className="text-muted">
                                                                    {state.user.street && state.user.street !== "" ? state.user.street : application.person.street}, {state.user.zip && state.user.zip !== "" ? state.user.zip : application.person.zip} {state.user.city && state.user.city !== "" ? state.user.city : application.person.city}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th className="ps-0" scope="row">{t('lbl_member_status')}:</th>
                                                                <td className="text-muted"> {application.rejectedUntil ? (
                                                                    <div>
                                                                        {t('lbl_member_rejected_until')} {application.rejectedUntil && moment(application.rejectedUntil).format('DD.MM.YYYY')}
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        {t('lbl_member_since')} {application.approvedDate && moment(application.approvedDate).format('DD.MM.YYYY')} {t('lbl_until')} {application.validUntil && moment(application.validUntil).format('DD.MM.YYYY')} {application.status === 'canceled' ? `(${t('lbl_canceled')})` : ''} {application.status === 'suspended' ? `(${t('lbl_suspended')})` : ''}
                                                                    </div>
                                                                )}
                                                                </td>
                                                            </tr>
                                                            <tr className={'desktopStatus'}>
                                                                <th className="ps-0" scope="row">{t('lbl_status')}:</th>
                                                                <td className="text-muted">
                                                                    <StatusBadge status={application.status} />
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )}
                                            </div>
                                            <div className='col-xxl-3' style={{display: "flex", justifyContent: "center", alignItems: "center", flexWrap: 'wrap'}}>
                                                <div className="profile-user position-relative d-inline-block mx-auto mb-4">
                                                    {state.profileImageUrl ? (
                                                        <img src={`${config.serverUrl}${state.profileImageUrl}`} className='rounded-circle avatar-xl img-thumbnail user-profile-image' alt="Selected"/>
                                                    ) : (
                                                        <span style={{display: "flex", justifyContent: "center", alignItems: "center"}} className='rounded-circle avatar-xl img-thumbnail user-profile-image'>{t('lbl_no_image')}</span>
                                                    )}
                                                </div>
                                                <div>
                                                    <QRCodeSVG value={window.location.href} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            )
                        )}
                    </div>
                </div>
            )}

            {!state.authenticated && (
                <div className='text-danger'>
                    <span className="bg-danger-subtle text-danger">Du bist nicht authorisiert für diese Seite.</span>
                </div>
            )}
        </Fragment>
    );
};

export default MemberCard;
