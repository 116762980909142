import React, { useState, useContext, createContext, useEffect } from 'react';
import axios from 'axios';
import Service from "../services/Service";
import config from '../config/config.json'

const authContext = createContext();

export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
    return useContext(authContext);
};

function useProvideAuth() {
    const [user, setUser] = useState(null);

    const reloadLogin = async (token) => {
        try{
            const response = await axios.get(`${config.serverUrl}/api/v1/auth`, {headers:{'X-Auth-Token': token}});
            Service.token = token;
            setUser(response.data);
        } catch (err) {
            return false;
        }
        return true;
    }

    const login = async (state) => {
        try {
            const response = await axios.post(`${config.serverUrl}/api/v1/auth`, state);
            Service.token = response.data.token;
            setUser(response.data);
            localStorage.setItem('authToken', response.data.token);
        } catch (err) {
            throw err;
        }
    };
    const signup = async (state) => {
        try {
            await axios.post(`${config.serverUrl}/api/v1/signup`, state);
        } catch (err) {
            throw err;
        }
    };

    const deleteUser = async (state) => {
        try {
            await axios.post(`${config.serverUrl}/api/v1/deleteaccount`, state);
        } catch (err) {
            throw err;
        }
    };

    const sendPasswordResetEmail = async (state) => {
        try {
            await axios.post(`${config.serverUrl}/api/v1/sendpasswordreset`, state);
        } catch (err) {
            throw err;
        }
    };
    const confirmPasswordReset = async (token, state) => {
        try {
            await axios.post(`${config.serverUrl}/api/v1/resetpassword?token=${token}`, state);
        } catch (err) {
            throw err;
        }
    };

    const signout = () => {
        localStorage.removeItem('authToken');
        setUser(null);
    };

    return {
        user,
        login,
        deleteUser,
        reloadLogin,
        signup,
        signout,
        sendPasswordResetEmail,
        confirmPasswordReset,
    };
}
