const DE_de = {
    txt_passwordchange: 'Passwort ändern',
    txt_passwordnotequal: 'Passwörter stimmen nicht überein',
    txt_passwordconfirm: 'Passwort bestätigen',
    txt_newpassword: 'Neues Passwort',
    txt_changepasswordnow: 'Ändern Sie jetzt Ihr Passwort!',
    txt_welcomeback: 'Willkommen zurück!',
    txt_current_deleteRequests: 'Aktuelle Löschungsanfragen',
    txt_members_deleteRequest: 'Mitglieder Löschungsanfragen',
    txt_deletedFlag_true: 'Ihr Konto wurde zum Löschen beantragt!',
    lbl_deleted: 'Ihr Konto wurde erfolgreich zum Löschen beantragt!',
    txt_signin: 'Einloggen um mit DCV forzufahren.',
    lbl_invalid: 'Ungültige Anmeldedaten!',
    lbl_password_change_success: 'Passwort erfolgreich geändert! Bitte melden Sie sich mit Ihrem neuen Passwort an.',
    lbl_password_change_check: 'Ihr Passwort kann geändert werden! Bitte überprüfen Sie Ihre E-Mails.',
    lbl_email: 'E-Mail',
    lbl_email_input: 'E-Mail eingeben',
    lbl_password_forgot: 'Passwort vergessen?',
    lbl_password: 'Passwort',
    lbl_password_input: 'Passwort eingeben',
    lbl_remember: 'Erinnere dich an mich',
    btn_signin: 'Anmelden',
    txt_noaccount: 'Sie haben noch kein Konto?',
    btn_signup: 'Registrieren',
    lbl_is_required: 'wird benötigt',
    txt_welcome: 'Willkommen bei DCV!',
    txt_signup: 'Registrieren Sie sich, um fortzufahren.',
    btn_register: 'Registrieren',
    txt_error_email_exist: 'Diese E-Mail existiert bereits. Wenn Sie Ihr Passwort vergessen haben oder eine neue Bestätigungs-E-Mail benötigen, klicken Sie',
    btn_here: 'hier',
    txt_have_account: 'Sie haben bereits ein Konto?',
    txt_password_forgot: 'Haben Sie Ihr Passwort vergessen?',
    txt_to_deleteaccount: 'Sie wollen Ihren Account löschen lassen?',
    btn_to_deleteaccount: 'Beantragen',
    txt_delete_account: 'Sie wollen Ihr Account löschen lassen?',
    txt_back_to_login: 'Zurück zum Login?',
    btn_delete_account: '"Account löschen" beantragen',
    txt_no_problem_delete_account: 'Kein Problem. Beachten Sie aber, dass wenn Sie noch einen laufenden Vertrag haben, dieser erst beendet werden muss.',
    txt_no_problem: 'Kein Problem.',
    btn_reset_password: 'Passwort zurücksetzen',
    txt_error_email_not_exist: 'Diese E-Mail existiert nicht. Sie können sich registrieren',
    txt_thankyou: 'Vielen Dank!',
    txt_check_mails: 'Überprüfen Sie Ihre E-Mails, um fortzufahren.',
    txt_thanks_register: 'Vielen Dank für Ihre Registrierung. Sie haben von uns eine Bestätigungs-E-Mail erhalten, mit der Sie anschließend Ihr Passwort festlegen können.',
    btn_to_login: 'Zurück zur Anmeldung',
    txt_hello: 'Hallo',
    txt_first_login: 'Willkommen! Dies ist Ihr erster Login!',
    txt_last_login: 'Letzter Login',
    txt_requests: 'Anfragen',
    txt_dashboard_status_open: 'Status: offen',
    txt_current_requests: 'Aktuelle Anfragen',
    txt_members: 'Mitglieder',
    txt_current_members: 'Aktuelle Mitglieder',
    lbl_member_card: 'Mitglieder Ausweis',
    lbl_member_form: 'Mitglieder Antrag',
    lbl_profile: 'Profil',
    lbl_menu: 'Menü',
    lbl_logout: 'Ausloggen',
    lbl_no_image: 'Kein Bild',
    lbl_saved: 'Gespeichert!',
    lbl_current_user_information: 'Aktuelle Benutzerinformationen',
    lbl_firstname: 'Vorname',
    lbl_firstname_input: 'Geben Sie Ihren Vornamen ein',
    lbl_lastname: 'Nachname',
    lbl_lastname_input: 'Geben Sie Ihren Nachnamen ein',
    lbl_street: 'Straße & Nr.',
    lbl_street_input: 'Geben Sie Ihre Straße & Nr. ein',
    lbl_zip: 'PLZ',
    lbl_zip_input: 'Geben Sie Ihre PLZ ein',
    lbl_city: 'Stadt',
    lbl_city_input: 'Geben Sie Ihre Stadt ein',
    btn_save: 'Speichern',
    txt_current_request_status: 'Ihre Anfrage befindet sich derzeit im Status:',
    txt_email_if_changes: 'Sie erhalten eine E-Mail, wenn sich dies ändert.',
    lbl_request_for: 'Anfrage für',
    lbl_current_status: 'Aktueller Status',
    lbl_created_date: 'Erstellungsdatum',
    lbl_name: 'Name',
    lbl_address: 'Adresse',
    lbl_date_of_foundation: 'Gründungsdatum',
    lbl_registration_nr: 'Handelsregistereintrag (soweit vorhanden)',
    lbl_contact_person: 'Kontaktperson',
    lbl_phone: 'Telefon',
    lbl_birth_date: 'Geburtsdatum',
    lbl_profile_details: 'Profil Details',
    lbl_choose_register: 'Wählen Sie aus, als was Sie den Mitgliedsantrag ausfüllen wollen.',
    btn_company: 'Als Unternehmen',
    btn_person: 'Als Einzelperson',
    lbl_application_exist: 'Sie haben bereits eine bestehende Anwendung.',
    lbl_company: 'Unternehmen',
    lbl_company_name: 'Firmenname',
    lbl_company_name_input: 'Geben Sie Ihren Firmennamen ein',
    lbl_registration_nr_input: 'Geben Sie Ihren Handelsregistereintrag ein',
    lbl_company_contact_person: 'Kontaktperson des Unternehmens',
    lbl_position: 'Position',
    lbl_position_input: 'Geben Sie die Position der Kontaktperson ein',
    lbl_contact_firstname: 'Vorname der Kontaktperson',
    lbl_contact_firstname_input: 'Geben Sie den Vornamen der Kontaktperson ein',
    lbl_contact_lastname: 'Nachname der Kontaktperson',
    lbl_contact_lastname_input: 'Geben Sie den Nachnamen der Kontaktperson ein',
    lbl_phone_input: 'Geben Sie die Telefonnummer ein',
    lbl_apply_to_become_member: 'Hiermit beantrage ich die Fördermitgliedschaft  im Verein "DCV"',
    lbl_check_agb: 'Ich habe die Satzung sowie die Mitteilung über die aktuelle Jahresbeitragshöhe erhalten und erkläre mich damit einverstanden.',
    lbl_check_dsgvo: 'Mir ist bewusst, dass meine Daten elektronisch zum Zwecke der Vereinsarbeit gespeichert und verarbeitet werden. Die Daten werden nur für die Aktivitäten des Vereins sowie der Deutscher Caravan Verband GmbH erfasst und nicht an Dritte weitergeben. Separate Autorisierungen finden Sie unten.“',
    lbl_person: 'Person',
    lbl_registered_as: 'Registriert als',
    lbl_status: 'Status',
    lbl_member_status: 'Mitglieds Status',
    lbl_member_rejected_until: 'Mitglied abgelehnt bis',
    lbl_member_since: 'Mitglied seit',
    lbl_until: 'bis',
    lbl_suspended: 'Ausgesetzt',
    lbl_canceled: 'Beendet',
    lbl_user_mail: 'Benutzer-E-Mail',
    lbl_person_contact: 'Person / Kontakt',
    lbl_approved_date: 'Genehmigungsdatum',
    lbl_valid_until: 'Gültig bis',
    lbl_rejected_until: 'Abgelehnt bis',
    lbl_actions: 'Aktionen',
    lbl_open: 'Offen',
    lbl_pending: 'Ausstehend',
    lbl_rejected: 'Abgelehnt',
    lbl_approved: 'Genehmigt',
    lbl_no_company: 'Kein Unternehmen',
    lbl_no_open_requests: 'Keine offenen Anfragen',
    lbl_no_approved_requests: 'Keine genehmigten Anfragen',
    lbl_no_rejected_requests: 'Keine abgelehnten Anfragen',
    lbl_no_pending_requests: 'Keine ausstehenden Anfragen',
    lbl_no_canceled_requests: 'Keine stornierten Anfragen',
    lbl_no_suspended_requests: 'Keine ausgesetzten Anfragen',
    lbl_application_details: 'Antragsdetails',
    lbl_user: 'Benutzer',
    lbl_application_information: 'Antragsinformationen',
    lbl_new_status: 'Neuer Status',
    lbl_error_application_not_approved: 'Antrag wurde nicht genehmigt',
    lbl_error_application_not_rejected: 'Antrag wurde nicht abgelehnt',
    lbl_send_mail: 'Möchten Sie eine E-Mail senden?',
    lbl_yes: 'Ja',
    lbl_no: 'Nein',
    lbl_company_information: 'Unternehmensinformationen',
    lbl_contact_person_information: 'Kontaktpersoneninformation',
    btn_be_member: 'Fördermitglied werden',
    lbl_customer: 'Kunde',
    lbl_member: 'Fördermitglied',
    lbl_administrator: 'Administrator',
    lbl_extra_info: 'Zusätzliche Informationen',
    lbl_extraInfo_textarea: 'Geben Sie zusätzliche Informationen ein',
}

export default DE_de;
